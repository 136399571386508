import { Typography, Avatar } from "@material-tailwind/react";

const TeamMembers = ({ teamMembers, onSelectTeamMember }) => {
  const TeamMember = ({ user }) => {
    return (
      <div
        key={user.id}
        id={user.id}
        onClick={() => onSelectTeamMember(user)}
        className="flex items-center w-[290px] p-2 min-w-min cursor-pointer hover:bg-gray-100"
      >
        <Avatar src={user.data.avatar} alt="avatar" />
        <Typography color="gray" variant="h6" className="ml-5">
          {user.data.display_name}
        </Typography>
      </div>
    );
  };

  return (
    <div className="mt-2">
      <Typography color="gray" variant="h5">
        Book a Meeting with
      </Typography>
      <div className="gap-4">
        {teamMembers &&
          teamMembers.map((teamMember) => {
            return <TeamMember key={teamMember.ID} user={teamMember} />;
          })}
      </div>
    </div>
  );
};

export default TeamMembers;
