function findAvailableOneHourSlots(date, tasks) {
  const numDays = (y, m) => new Date(y, m, 0).getDate();
  const daysInMonth = numDays(date.getFullYear(), date.getMonth() + 1);

  const availableSlots = [];

  let startTime = process.env.REACT_APP_AVAILABILITY_START_TIME;
  let endTime = process.env.REACT_APP_AVAILABILITY_END_TIME;

  // Initialize available slots for each day
  for (let day = 1; day <= daysInMonth; day++) {
    availableSlots[day] = [];

    const taskDate = new Date(date.getFullYear(), date.getMonth(), day);
    const isWeekend = taskDate.getDay() === 0 || taskDate.getDay() === 6;

    // is it a day before the current day?
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Is this day in the past?
    const isPastDay = taskDate < today;

    // Is this day today?
    const isToday = taskDate.toDateString() === today.toDateString();

    for (let hour = startTime; hour < endTime; hour++) {
      // Mark slots as unavailable during weekends
      availableSlots[day][hour] = (!isWeekend && !isPastDay) || false;

      // Mark slots as unavailable during the current hour
      if (isToday && hour <= new Date().getHours()) {
        availableSlots[day][hour] = false;
      }
    }
  }

  // Mark unavailable slots based on tasks
  tasks.forEach((task) => {
    const taskDateStart = new Date(task.start);
    const taskDateEnd = new Date(task.end);
    const taskDay = taskDateStart.getDate();
    const taskStartHour = taskDateStart.getHours();
    const taskEndHour = taskDateEnd.getHours();

    // Mark slots as unavailable during the task period
    for (let hour = taskStartHour; hour < taskEndHour; hour++) {
      availableSlots[taskDay][hour] = false;
    }
  });

  // Identify available one-hour slots
  const datesWithSlots = [];
  for (let day = 1; day <= daysInMonth; day++) {
    let slotCount = 0;
    for (let hour = startTime; hour < endTime; hour++) {
      slotCount = availableSlots[day][hour] ? slotCount + 1 : slotCount;
    }
    datesWithSlots.push({
      date: new Date(date.getFullYear(), date.getMonth(), day),
      slots: slotCount,
    });
  }
  return { datesWithSlots, availableSlots, daysInMonth };
}
export { findAvailableOneHourSlots };
