import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import TeamMembers from "./TeamMembers";
import TeamMember from "./TeamMember";
import DateSelector from "./DateSelector";
import Branding from "./Branding";

import { useBooking } from "../context/BookingContext";

import UserService from "../services/Users";

const SelectionView = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  const {
    activeTeamMember,
    setActiveTeamMember,
    setSelectedDay,
    setSelectedTime,
  } = useBooking();

  const onSelectTeamMember = (teamMember) => {
    setActiveTeamMember(teamMember);

    setSelectedDay(null);
    setSelectedTime(null);
  };

  const { userName } = useParams();

  useEffect(() => {
    const getTeamMembers = async () => {
      try {
        const data = await UserService.getActiveUsers();
        setTeamMembers(data);

        if (userName) {
          const user = data.find(
            (user) =>
              user.data.display_name.split(" ")[0].toLowerCase() ==
              userName.toLowerCase()
          );
          setActiveTeamMember(user);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getTeamMembers();
  }, []);

  return (
    <main className="flex items-center justify-center h-screen min-h-[450px]">
      <div className="container flex flex-col sm:flex-row border h-5/6 min-h-[400px] rounded-2xl drop-shadow-xl">
        <div className="flex flex-col p-4 bg-white border rounded-l-lg sm:w-1/3 min-w-min">
          <div className="flex items-center justify-center mb-2">
            <img
              src="https://hub.fl1digital.com/wp-content/uploads/2018/04/fl1-logo.png"
              className="w-[150px]"
            />
          </div>
          <hr className="" border="true" />
          <div className="flex flex-col items-center justify-center mb-2 h-3/4">
            {activeTeamMember && activeTeamMember.ID > 0 ? (
              <TeamMember
                onSelectTeamMember={onSelectTeamMember}
                teamMember={activeTeamMember}
              />
            ) : (
              <TeamMembers
                onSelectTeamMember={onSelectTeamMember}
                teamMembers={teamMembers}
              />
            )}
          </div>
        </div>
        <div className="p-4 text-white bg-white border rounded-r-lg md:w-3/4">
          {activeTeamMember && activeTeamMember.ID > 0 && (
            <DateSelector TeamMember={activeTeamMember} />
          )}
          <div className="absolute top-0 right-0  h-[120px] w-[120px] overflow-hidden">
            <Branding />
          </div>
        </div>
      </div>
    </main>
  );
};

export default SelectionView;
