import { useBooking } from "../context/BookingContext";

import { Typography, Button } from "@material-tailwind/react";
import gcal from "../assets/gcal.png";
import ocal from "../assets/ocal.png";
import ical from "../assets/ical.png";

const ConfirmationView = () => {
  const { booking } = useBooking();

  const onClickOutlookCalendar = () => {
    window.open(booking.outlookCalendarUrl, "_blank");
  };

  const onClickGoogleCalendar = () => {
    window.open(booking.googleCalendarUrl, "_blank");
  };

  const onClickICal = () => {
    const element = document.createElement("a");
    const file = new Blob([booking.iCalEvent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "event.ics";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <div className="flex flex-col items-center justify-center h-2/3">
      <Typography variant="paragraph" color="blue-gray">
        Click on the options below to add the meeting to your calendar
      </Typography>
      <Button
        size="lg"
        variant="outlined"
        color="blue-gray"
        className="flex items-center w-full h-10 gap-3 mb-2"
        onClick={onClickGoogleCalendar}
      >
        <img src={gcal} alt="metamask" className="w-8 h-8" />
        Add to Google Calendar
      </Button>

      <Button
        size="lg"
        variant="outlined"
        color="blue-gray"
        className="flex items-center w-full h-10 gap-3 mb-2"
        onClick={onClickOutlookCalendar}
      >
        <img src={ocal} alt="metamask" className="w-6 h-6" />
        Add to Outlook Calendar
      </Button>

      <Button
        size="lg"
        variant="outlined"
        color="blue-gray"
        className="flex items-center w-full h-10 gap-3 mb-2"
        onClick={onClickICal}
      >
        <img src={ical} alt="metamask" className="w-6 h-6" />
        Download ICal
      </Button>
    </div>
  );
};

export default ConfirmationView;
