import React from "react";

const TimeSelector = ({ date, entries, onTimeSelected }) => {
  const { availableSlots } = entries;

  const slotsForDay = availableSlots[date.getDate()];
  const slots = Object.entries(slotsForDay);

  const renderTimeSlot = (hour) => {
    const [hourNumber, isAvailable] = hour;

    if (!isAvailable) {
      return <div key={hour}></div>;
    } else {
      return (
        <div
          onClick={() => onTimeSelected(hour)}
          className="w-[250px] h-[50px] text-gray-500 text-sm m-2 p-3 flex items-center justify-center border border-gray-400 rounded-md hover:bg-gray-100 focus:outline-none focus:border-gray-500"
          key={hour}
        >
          {hour}:00
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {slots.map((hour) => renderTimeSlot(hour))}
    </div>
  );
};

export default TimeSelector;
