import axios from "axios";

class EmailService {
  static async sendEmail(options) {
    try {
      // Replace 'YOUR_SENDGRID_API_KEY' with your SendGrid API key
      const serverUrl =
        process.env.REACT_APP_EMAIL_SEND_API ||
        "http://localhost:3001/send-email"; // Update with your server URL

      const response = await axios.post(serverUrl, {
        fromEmail: options.fromEmail,
        fromName: options.fromName,
        email: options.email,
        template: "d-4addaf8a77b3426b98885143ec8e2faf",
        subject: options.subject,
        meeting: options.meeting,
        attachments: [
          {
            content: options.meeting.iCalEvent,
            filename: "event.ics",
            type: "text/calendar",
            disposition: "attachment",
            encoding: "base64",
          },
        ],
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  static async generateGoogleCalendarURL(
    activeTeamMember,
    booking,
    start,
    end,
    title,
    location
  ) {
    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${start
      .toISOString()
      .replace(/-|:|\.\d+/g, "")}/${end
      .toISOString()
      .replace(/-|:|\.\d+/g, "")}&details=${encodeURI(
      title
    )}&location=${location}&add=${booking.email}&sf=true&output=xml`;
    return googleCalendarUrl;
  }

  static async generateOutlookCalendarURL(
    activeTeamMember,
    booking,
    start,
    end,
    title,
    location
  ) {
    const outlookCalendarUrl = `https://outlook.live.com/owa/?rru=addevent&startdt=${start.toISOString()}&enddt=${end.toISOString()}&subject=${encodeURI(
      title
    )}&body=${encodeURI(booking.notes) || ""}&location=${location}&attendees=${
      booking.email
    }`;

    return outlookCalendarUrl;
  }

  static async generateICal(
    activeTeamMember,
    booking,
    start,
    end,
    title,
    location
  ) {
    const icalEvent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${title}
DESCRIPTION:${booking.notes}
LOCATION:${location}
DTSTART:${start.toISOString().replace(/-|:|\.\d+/g, "")}
DTEND:${end.toISOString().replace(/-|:|\.\d+/g, "")}
END:VEVENT
END:VCALENDAR`;
    return icalEvent;
  }
}

export default EmailService;
