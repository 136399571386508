const getMonthStartEndDates = (date) => {
  const start = 1;

  const numDays = (y, m) => new Date(y, m, 0).getDate();
  const end = numDays(date.getFullYear(), date.getMonth() + 1);

  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const first = dateFormatWordPress(startDate);
  const last = dateFormatWordPress(endDate);

  return {
    first,
    last,
  };
};

const dateFormatWordPress = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export { getMonthStartEndDates, dateFormatWordPress };
