import { Typography } from "@material-tailwind/react";

import { useBooking } from "../context/BookingContext";

import { TimeIcon, CalendarIcon, GlobeIcon } from "../svg/icons";

import { Button } from "@material-tailwind/react";

const TeamMember = ({ teamMember, onSelectTeamMember }) => {
  const { selectedDay, selectedTime, setSelectedTime } = useBooking();
  const onClickBack = () => {
    setSelectedTime(null);
  };

  return (
    <>
      <div className="w-[290px] mt-5 h-100">
        <img
          src={teamMember.data.avatar}
          className="w-[60px] rounded-full mb-2"
        />
        <Typography color="gray" variant="h6">
          {teamMember.data.display_name}
        </Typography>

        <Typography color="gray" variant="h4">
          30 Minute Meeting
        </Typography>

        <div className="flex flex-col gap-4 mt-6">
          {selectedDay && (
            <div className="flex">
              <CalendarIcon color="white" size="25px" />
              <Typography color="gray" variant="paragraph" className="ml-2">
                {selectedDay.toDateString()}
              </Typography>
            </div>
          )}
          {selectedTime && (
            <div className="flex">
              <TimeIcon color="white" size="25px" />
              <Typography color="gray" variant="paragraph" className="ml-2">
                {selectedTime}:00-{selectedTime}:30PM
              </Typography>
            </div>
          )}
          <div className="flex">
            <GlobeIcon color="white" size="25px" />
            <Typography color="gray" variant="paragraph" className="ml-2">
              UK, Ireland, Lisbon Time
            </Typography>
          </div>
          {selectedTime && (
            <Button
              variant="text"
              className="flex items-center justify-center p-2 m-1 rounded-full hover:bg-gray-100 focus:outline-none focus:border-gray-500"
              ripple={true}
              onClick={onClickBack}
            >
              Change Time
            </Button>
          )}
        </div>
      </div>
      <div className="flex items-end justify-between flex-grow  w-[280px]"></div>
    </>
  );
};

export default TeamMember;
