import { useState } from "react";
import {
  Card,
  Input,
  Button,
  Typography,
  Radio,
  Textarea,
  Spinner,
  Alert,
} from "@material-tailwind/react";

import { useBooking } from "../context/BookingContext";
import { isValidEmail } from "../utils/validation";
import TaskService from "../services/Task";
import EmailService from "../services/Email";

import ConfirmationView from "./ConfirmationView";

const STATUS = {
  BOOKING: 0,
  PROCESSING: 1,
  SUCCESS: 2,
  ERROR: 3,
};

const BookingView = ({ timeSlot, selectedDay }) => {
  const [addGuests, setAddGuests] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [status, setStatus] = useState(STATUS.BOOKING);

  const { activeTeamMember, booking, setBooking } = useBooking();

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setBooking({ ...booking, [name]: value });
  };

  const validateForm = () => {
    if (!booking.name) {
      return false;
    }
    if (!booking.email) {
      return false;
    }
    return true;
  };

  const onBookMeeting = async (e) => {
    const bIsVaild = validateForm();
    setHasErrors(bIsVaild);
    if (!bIsVaild) {
      return;
    }

    setStatus(STATUS.PROCESSING);
    const meeting_id = process.env.REACT_APP_MEETING_ID;
    const title = `Meeting with ${booking.name} and ${activeTeamMember.data.display_name}`;
    const notes = `${booking.notes}\n\nGuests: ${booking.guests_emails}\n\nMeeting Type: ${booking.type}\n\nPhone: ${booking.phone}\n\n${meeting_id}`;

    // create a Date object from the selected day and time
    const date = new Date(selectedDay);
    date.setHours(timeSlot[0]);

    // create an end time by adding an hour to the start time
    const endDate = new Date(date);
    endDate.setHours(endDate.getHours() + 1);

    const outlookCalendarUrl = await EmailService.generateOutlookCalendarURL(
      activeTeamMember,
      booking,
      date,
      endDate,
      title,
      "online"
    );

    const googleCalendarUrl = await EmailService.generateGoogleCalendarURL(
      activeTeamMember,
      booking,
      date,
      endDate,
      title,
      "online"
    );

    const iCalEvent = await EmailService.generateICal(
      activeTeamMember,
      booking,
      date,
      endDate,
      title,
      "online"
    );

    setBooking({
      ...booking,
      notes: notes,
      outlookCalendarUrl: outlookCalendarUrl,
      googleCalendarUrl: googleCalendarUrl,
      iCalEvent: iCalEvent,
    });

    setStatus(STATUS.SUCCESS);

    // Format the date for the email
    const formatedDate = date.toLocaleTimeString("en-GB", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const formatedEndDate = endDate.toLocaleTimeString("en-GB", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    // Create a Google Meet URL
    const meetingUrl =
      process.env.REACT_APP_MEETING_ID ||
      `https://meet.google.com/dhy-isjf-rjr`;

    const meeting = {
      title,
      formatedDate,
      formatedEndDate,
      outlookCalendarUrl,
      googleCalendarUrl,
      iCalEvent,
      ...booking,
      meetingUrl,
    };

    try {
      // Set up the email options
      const optionsClient = {
        fromEmail: activeTeamMember.data.user_email,
        fromName: activeTeamMember.data.display_name,
        email: booking.email,
        subject: `Meeting Booked: ${activeTeamMember.data.display_name}, FL1 Digital`,
        meeting: meeting,
      };

      // Set up the email options
      const optionsFL1 = {
        fromEmail: booking.email,
        fromName: booking.name,
        email: activeTeamMember.data.user_email,
        subject: `Meeting Booked: ${booking.name}`,
        meeting: meeting,
      };

      // send the email confimation to the client
      EmailService.sendEmail(optionsClient);

      // send the email confimation to the FL1 team
      EmailService.sendEmail(optionsFL1);

      // create the task
      const res = await TaskService.createTask(
        title,
        activeTeamMember.data.ID,
        date,
        endDate,
        process.env.REACT_APP_PROJECT_ID,
        notes
      );
      if (res) {
        setStatus(STATUS.SUCCESS);
      }
    } catch (error) {
      setStatus(STATUS.ERROR);
      console.log(error);
    }
  };

  switch (status) {
    case STATUS.SUCCESS:
      return (
        <div className="flex flex-col justify-center h-2/3">
          <Typography variant="h4" color="blue-gray">
            Success!
          </Typography>
          <ConfirmationView />
        </div>
      );
    case STATUS.ERROR:
      return (
        <Card color="transparent" shadow={false}>
          <Typography variant="h4" color="blue-gray">
            Error!
          </Typography>
          <Typography color="gray" className="font-normal">
            There was an error booking your meeting. Please try again.
          </Typography>
        </Card>
      );

    case STATUS.PROCESSING:
      return (
        <Card color="transparent" shadow={false}>
          <Typography color="gray" className="font-normal">
            Please wait while we book your meeting.
          </Typography>
          <Spinner className="w-16 h-16 text-gray-900/50" />
        </Card>
      );
    case STATUS.BOOKING:
      return (
        <Card color="transparent" shadow={false}>
          <Typography variant="h4" color="blue-gray">
            Enter Details
          </Typography>
          <Typography color="gray" className="font-normal">
            Nice to meet you! Enter your details to confirm the meeting.
          </Typography>
          <form className="max-w-screen-lg mt-4 mb-2 w-80 sm:w-96">
            <div className="flex flex-col gap-4 mb-1">
              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Your Name
              </Typography>
              <Input
                type="text"
                size="lg"
                error={!booking.name}
                name="name"
                onChange={onInputChange}
                value={booking.name}
              />
              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Your Email
              </Typography>
              <Input
                type="email"
                name="email"
                size="lg"
                onChange={onInputChange}
                value={booking.email}
                placeholder="name@mail.com"
                error={!isValidEmail(booking.email)}
              />
              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Preferred Meeting Type
              </Typography>
              <div className="flex gap-10">
                <Radio
                  name="type"
                  label="Online Meeting"
                  checked={booking.type === "zoom"}
                  onChange={onInputChange}
                  value="zoom"
                />
                <Radio
                  name="type"
                  label="Phone Call"
                  onChange={onInputChange}
                  checked={booking.type === "phone"}
                  value="phone"
                />
              </div>
              {booking.type === "phone" && (
                <>
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    Best Contact Number
                  </Typography>
                  <Input
                    type="text"
                    name="phone"
                    size="lg"
                    onChange={onInputChange}
                    value={booking.phone}
                  />
                </>
              )}
              {addGuests && (
                <>
                  <Button
                    className="btn secondary"
                    color="gray"
                    onClick={() => setAddGuests(!addGuests)}
                  >
                    Add Guests
                  </Button>
                  <Textarea
                    name="guests_emails"
                    label="Guest Email(s)"
                    onChange={onInputChange}
                    value={booking.guests_emails}
                  />
                </>
              )}
              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Notes for the meeting
              </Typography>
              <Textarea
                name="notes"
                placeholder="Please share anything that will help prepare for our meeting."
                onChange={onInputChange}
                value={booking.notes}
                error={!booking.notes}
              />
              <Button color="red" onClick={onBookMeeting}>
                Book Meeting
              </Button>
              {hasErrors && (
                <Alert variant="ghost" className="h-14">
                  The highlighted fields are required.
                </Alert>
              )}
            </div>
          </form>
        </Card>
      );
  }
};

export default BookingView;
