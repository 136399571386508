import React, { useState, createContext, useContext } from "react";

// create a context object
const BookingContext = createContext(null);

/* const bookingInfo = {
  name: "Mr Client",
  email: "samjas73@gmail.com",
  guests_emails: "jason@fl1.digital",
  notes: "I'd like to discuss the new project",
  phone: "01727 739812",
  type: "zoom",
}; */

const bookingInfo = {
  name: "",
  email: "",
  guests_emails: "",
  notes: "",
  phone: "",
  type: "zoom",
};

// create a custom hook to access the context
export const useBooking = () => useContext(BookingContext);
// create a provider component
export default function BookingProvider({ children }) {
  const [activeTeamMember, setActiveTeamMember] = useState({
    id: 0,
    name: "",
  });

  const [booking, setBooking] = useState(bookingInfo);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setSelectedTime] = useState();

  const value = {};

  return (
    <BookingContext.Provider
      value={{
        activeTeamMember,
        setActiveTeamMember,
        booking,
        setBooking,
        selectedDay,
        setSelectedDay,
        selectedTime,
        setSelectedTime,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
}
