import { useEffect, useState } from "react";

import TaskService from "../services/Task";

import { findAvailableOneHourSlots } from "../utils/tasks";

import { Button, Card, Spinner, Typography } from "@material-tailwind/react";

import MonthView from "./MonthView";
import TimeSelector from "./TimeSelector";
import BookingView from "./BookingView";

import { useBooking } from "../context/BookingContext";

const DateSelector = ({ TeamMember }) => {
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState();

  const { selectedDay, setSelectedDay, selectedTime, setSelectedTime } =
    useBooking();

  const onMonthChange = (offSet) => {
    const date = new Date(selectedMonth);
    date.setMonth(date.getMonth() + offSet);
    setSelectedMonth(date);

    getTasks(date);
  };

  const getTasks = async (month) => {
    setIsLoading(true);
    if (month === undefined) return;
    // get a list of tasks for the user/team member for the month
    const tasks = await TaskService.getTasksForUserForMonth(
      TeamMember.ID,
      month
    );
    // Get the dates with slots and available slots for those days
    const data = findAvailableOneHourSlots(month, tasks);
    setEntries(data);
    setIsLoading(false);
  };

  useEffect(() => {
    // get the current month
    const today = new Date();
    setSelectedMonth(today);

    getTasks(today);
  }, []);

  const getTime = () => {
    const d = new Date(),
      h = (d.getHours() < 10 ? "0" : "") + d.getHours(),
      m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    return h + ":" + m;
  };

  return (
    <div className="flex flex-col md:items-start lg:flex-row h-3/4">
      {selectedTime ? (
        <>
          <BookingView timeSlot={selectedTime} selectedDay={selectedDay} />
        </>
      ) : (
        <>
          <div className="w-[400px] p-4 text-white bg-white-500">
            <h1 className="text-sm font-bold text-gray-800">
              Select a Date & Time
            </h1>
            <div className="flex items-center justify-center">
              <Button
                variant="text"
                color="gray"
                className="flex items-center justify-center w-10 p-3 m-1 rounded-full hover:bg-blue-100 focus:outline-none focus:border-blue-500"
                onClick={() => onMonthChange(-1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                >
                  <path d="M380.6 81.7c7.9 15.8 1.5 35-14.3 42.9L103.6 256 366.3 387.4c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3l-320-160C6.8 279.2 0 268.1 0 256s6.8-23.2 17.7-28.6l320-160c15.8-7.9 35-1.5 42.9 14.3z" />
                </svg>
              </Button>
              <div className="text-gray-500 text-md ">
                {selectedMonth?.toLocaleString("default", { month: "long" })}
              </div>
              <Button
                variant="text"
                color="gray"
                className="flex items-center justify-center w-10 p-3 m-1 rounded-full hover:bg-blue-100 focus:outline-none focus:border-blue-500"
                onClick={() => onMonthChange(1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                >
                  <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4C1.9 395.3-4.5 414.5 3.4 430.3s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
                </svg>
              </Button>
            </div>
            <div className="flex-col items-center justify-center">
              {isLoading ? (
                <>
                  <Card color="transparent" shadow={false}>
                    <div className="flex items-center justify-center mt-6">
                      <Spinner className="w-16 h-16 text-gray-900/50" />
                    </div>
                  </Card>
                </>
              ) : (
                <>
                  <MonthView
                    month={selectedMonth}
                    entries={entries}
                    onSelectDay={setSelectedDay}
                  />
                  <div className="mt-5">
                    <div className="flex text-sm font-bold text-gray-900">
                      Time Zone
                    </div>
                    <div className="flex text-sm text-gray-800 ">
                      UK, Ireland, Portugal ({getTime()})
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {selectedDay && (
            <div className="flex flex-col items-center justify-center w-[400px]">
              <div className="text-sm text-gray-900 ">
                {selectedDay.toLocaleDateString("en-us", {
                  weekday: "long",
                  day: "numeric",
                  year: "numeric",
                  month: "long",
                })}
              </div>
              <TimeSelector
                date={selectedDay}
                entries={entries}
                onTimeSelected={setSelectedTime}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DateSelector;
