import { Button } from "@material-tailwind/react";

const MonthView = ({ month, entries, onSelectDay }) => {
  const { datesWithSlots, daysInMonth } = entries;

  const onClickDay = (day, isBookable) => {
    if (isBookable) {
      onSelectDay(day);
    } else {
      onSelectDay(day);
    }
  };

  const PlaceholderDay = ({ text }) => {
    return (
      <div className="h-[40px] w-[40px] text-gray-500 text-sm m-1 p-3 flex items-center justify-center">
        {text}
      </div>
    );
  };

  const DayHeader = ({ text }) => {
    return (
      <div className="h-[40px] w-[40px] text-gray-500 text-sm m-1 p-3 flex items-center justify-center">
        {text}
      </div>
    );
  };

  const DisplayDay = ({ day }) => {
    // Do we have slots for this day?
    let hasSlots = datesWithSlots[day.getDate() - 1].slots > 0;

    // Is this today?
    const isToday = day.toDateString() === new Date().toDateString();

    // Is this a weekend? if it is, there's nothing available
    const isWeekend = day.getDay() === 0 || day.getDay() === 6;
    hasSlots = hasSlots && !isWeekend;

    // build a list of classes for the day
    let className = "day-select";
    if (isToday) {
      className += " today";
    }
    if (hasSlots) {
      className += " slots";
    }

    if (hasSlots) {
      return (
        <Button
          variant="filled"
          color={isToday ? "red" : "gray"}
          className="flex items-center justify-center w-10 p-3 m-1 rounded-full hover:bg-gray-500 focus:outline-none focus:border-gray-500"
          onClick={() => onClickDay(day, hasSlots)}
        >
          {day.getDate()}
        </Button>
      );
    } else {
      return <PlaceholderDay text={day.getDate()} />;
    }
  };

  const days = [];
  for (let day = 1; day <= daysInMonth; day++) {
    const dayDate = new Date(month.getFullYear(), month.getMonth(), day);
    if (dayDate.getDate() === 1) {
      const dayOfWeek = dayDate.getDay();
      for (let i = 0; i < dayOfWeek - 1; i++) {
        days.push(<PlaceholderDay key={`ph-${i}`} text="" />);
      }
      days.push(<DisplayDay key={dayDate} day={dayDate} />);
    } else {
      days.push(<DisplayDay key={dayDate} day={dayDate} />);
    }
  }

  return (
    <div className="container flex flex-wrap items-center justify-center">
      <DayHeader text="MON" />
      <DayHeader text="TUE" />
      <DayHeader text="WED" />
      <DayHeader text="THU" />
      <DayHeader text="FRI" />
      <DayHeader text="SAT" />
      <DayHeader text="SUN" />
      <div className="flex flex-wrap justify-start">{days}</div>
    </div>
  );
};

export default MonthView;
