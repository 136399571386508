const isValidEmail = (email) => {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  return isValid;
};

const isValidPhone = (phone) => {
  return /^\d{11}$/.test(phone);
};

const isValidName = (name) => {
  return /^[a-zA-Z\s]+$/.test(name);
};

const isValidNotes = (notes) => {
  return /^[a-zA-Z\s]+$/.test(notes);
};

export { isValidEmail, isValidPhone, isValidName, isValidNotes };
